
import { Observer } from "mobx-react-lite"
import BaseButton from "../components/BaseButton/BaseButton"
import CTAButton from "../components/CTAButton/CTAButton"
import DefaultPageHeader from "../components/DefaultPageHeader/DefaultPageHeader"
import GridRow from "../components/GridRow/GridRow"
import GridRowEndColumn from "../components/GridRow/GridRowEndColumn"
import Line from "../components/Line/Line"
import PageEndCTASection from "../components/PageEndCTASection/PageEndCTASection"
import PageTemplate from "../components/PageTemplate/PageTemplate"
import Spacer from "../components/Spacer/Spacer"
import StandardTextBlock from "../components/StandardTextBlock/StandardTextBlock"
import TricolorMorphDef from "../components/TricolorMorphDef/TricolorMorphDef"
import Widowless from "../components/Widowless/Widowless"
import { useAllProducts } from "../content/products/_products.index"
import { useAppContext } from "../controllers/app.controller"
import { PageComponent } from "../types/gatsby.types"
import { isIE } from "../utils/browsers.utils"
import joinClassName from "../utils/className.utils"
import './plans.scss'

const PageCarePlans: PageComponent = props => {

  const { UI } = useAppContext();
  
  return <Observer children={() => (
    <PageTemplate
      {...props} // always include
      className="PageCarePlans"
      title="Care Plans"
      description=""
    >

      <TricolorMorphDef
        mobile={{
          R: 'M268.551 169.47L346.466 124.486L346.466 214.455L268.551 169.47Z',
          G: 'M190.637 169.47L268.552 124.486L268.552 214.455L190.637 169.47Z',
          B: 'M112.721 169.471L190.636 124.487L190.636 214.455L112.721 169.471Z',
        }}
        desktop={{
          R: "M1016.14 218.848L1141.47 146.487L1141.47 291.209L1016.14 218.848Z",
          G: "M890.805 218.848L1016.14 146.487L1016.14 291.209L890.805 218.848Z",
          B: "M765.472 218.848L890.805 146.487L890.805 291.209L765.472 218.848Z",
        }}
      />
      <DefaultPageHeader
        id="CarePlansPageHeader"
        className="CarePlansPageHeader"
        title="Care Plans"
      >
        <GridRow>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>
              Your website is your global shop window and it needs to keep working 24/7 to inform, educate, or provide eCommerce capabilities, and our care plans are here to ensure that.              </Widowless>
          </StandardTextBlock>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>
              We will provide software updates, system maintenance, security checks, and other UX and system-related aspects of your business. Allowing you to focus on your business.
            </Widowless>
          </StandardTextBlock>
          { 
            !isIE && <>
              {!UI.cssFeatures.grid && <div />}
              {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && <div />}
            </>
          }
          <GridRowEndColumn>
            <CTAButton
              title="Contact Us"
              to="/contact"
              ribbons
            // gaEvent={{ event: "contact-button--hero" }}
            >Contact Us</CTAButton>
          </GridRowEndColumn>
        </GridRow>
      </DefaultPageHeader>

      <div id="CarePlansPageContent" className="CarePlansPageContent PageSectionInner">
        <h2>Why avail of our care plans service?</h2>
        <Spacer size="lg"/>
        <ul className="CarePlansList">
          <li><span>Peace of mind</span></li>
          <li><span>No need to worry about any of your business’s technical aspects</span></li>
          <li><span>Avoid downtimes as much as possible through regular backups</span></li>
          <li><span>A dedicated team of customer support to assist you</span></li>
          <li><span>Competitive rates</span></li>
          {/* <li></li> */}
        </ul>
        <Spacer size="lg" />

        <GridRow>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            <Widowless>
              You dont have an inhouse IT team who can managing both the front and backend of your website? Contact us to provide an IT solution for you. It hard if you’re a startup and don’t have the funds to hire your own in-house IT team. Why not let our experts take care of everything so you can shift your focus to the more focal aspects of your business.            </Widowless>
            <Widowless>
              Our care plans services are divided into the following packages depending on your budget:
            </Widowless>
          </StandardTextBlock>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
            {/* <Widowless>
              Basically, we’ll be the ones to perform software updates, system maintenance, security checks, and other system-related aspects of your business. 
            </Widowless> */}
          </StandardTextBlock>
          { 
            !isIE && <>
              {!UI.cssFeatures.grid && <div />}
              {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && <div />}
            </>
          }
          <GridRowEndColumn>
            {/* <CTAButton
              title="Contact Us"
              to="/contact"
              ribbons
            // gaEvent={{ event: "contact-button--hero" }}
            >Contact Us</CTAButton> */}
          </GridRowEndColumn>
        </GridRow>

        {/* <StandardTextBlock animateTextEnter={{ delay: 200 }}>
          <Widowless>
            Managing both the front and backend of your website can be quite tedious. It gets even harder if you’re a startup and don’t have the funds to hire your own in-house IT team. Instead of doing them yourself, why not let our experts take care of everything so you can shift your focus to the more focal aspects of your business.
          </Widowless>
          <Widowless>
            Our care plans services are divided into the following packages depending on your budget:
          </Widowless>
          </StandardTextBlock> */}

        <Spacer size="lg"/>

        <CarePlansProductsList/>

        <Spacer size="lg"/>

        {/* <GridRow>
          <StandardTextBlock>
            <h2>Questions? Need something custom?</h2>
            <Spacer />
            <Widowless>
              Get started now. Choose a plan that is fit for purpose for your business’s needs.

            </Widowless>
            <Spacer />
            <CTAButton
              title="Contact Us"
              to="/contact"
              ribbons
          >Contact Us</CTAButton>
          </StandardTextBlock>
          <StandardTextBlock animateTextEnter={{ delay: 200 }}>
          </StandardTextBlock>
          { 
            !isIE && <>
              {!UI.cssFeatures.grid && <div />}
              {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && <div />}
            </>
          }
          <GridRowEndColumn>
          </GridRowEndColumn>
        </GridRow> */}

        <GridRow>
          <StandardTextBlock>
            <h2>Get started now!</h2>
            <Spacer />
            <Widowless>
            Choose a plan that is fit for purpose for your business’s needs

            </Widowless>
            <Spacer />
            {/* <CTAButton
              title="Contact Us"
              to="/contact"
              ribbons
          >Contact Us</CTAButton> */}
          </StandardTextBlock>
          { 
            !isIE && <>
              {!UI.cssFeatures.grid && <div />}
              {!UI.cssFeatures.grid && UI.viewport.width >= 1440 && <div />}
            </>
          }
          <GridRowEndColumn>
            {/* <CTAButton
              title="Contact Us"
              to="/contact"
              ribbons
            // gaEvent={{ event: "contact-button--hero" }}
            >Contact Us</CTAButton> */}
          </GridRowEndColumn>
        </GridRow>

        <Spacer size="lg"/>

        <FAQList/>

      </div>

      <Spacer size="pageMargin" />


      <Line />

      <PageEndCTASection />
      {/* <ContactForm preSelectedTopics={['websites']} /> */}

    </PageTemplate>
  )} />
  
}

function FAQToggle(e: any) {
  console.log('toggle');
  e.preventDefault();

  const FAQItem = e.currentTarget;

  if ( FAQItem instanceof Element) {
    if (FAQItem.classList.contains('open')) {
      FAQItem.classList.remove('open');
    } else {
      FAQItem.classList.add('open');
    }
  }
}

const FAQList = () => {
  return <ul className="FAQList">
    <li className="FAQItem" onClick={FAQToggle}>
      <h3 className="FAQQuestion">How long it will take to set up my Care Plan?</h3>
      <StandardTextBlock className="FAQAnswer" animateTextEnter={{ delay: 200 }}>
        <Widowless>
        Once you sign up and provide login credentials, we’ll have your plan set up within 48 hrs. Everything from speed improvements to security will be addressed by our team during on-boarding.
        </Widowless>
      </StandardTextBlock>
    </li>
    <Spacer></Spacer>
    <li className="FAQItem" onClick={FAQToggle}>
      <h3 className="FAQQuestion">Does monthly time rollover?</h3>
      <StandardTextBlock className="FAQAnswer" animateTextEnter={{ delay: 200 }}>
        <Widowless>
        Our care plans are designed to be monthly plans and as such we reserve your place in our queue and resources each month, therefore it does not roll over. 
        </Widowless>
      </StandardTextBlock>
    </li>
    <Spacer></Spacer>
    <li className="FAQItem" onClick={FAQToggle}>
      <h3 className="FAQQuestion">Do you manage WooCommerce websites?</h3>
      <StandardTextBlock className="FAQAnswer" animateTextEnter={{ delay: 200 }}>
        <Widowless>
        Websites with advanced functionality like this require our monthly eCommerce Plan. They take more resources for us to manage, test and keep running smoothly. We want to have you on the right plan so we are able to dedicate what’s needed to keep your website secure, fast, and open for business.
        </Widowless>
      </StandardTextBlock>
    </li>
    <Spacer></Spacer>
    <li className="FAQItem" onClick={FAQToggle}>
      <h3 className="FAQQuestion">What happens it my website goes down, is hacked, breaks, etc.?</h3>
      <StandardTextBlock className="FAQAnswer" animateTextEnter={{ delay: 200 }}>
        <Widowless>
        With our Premium Care plan we take monthly backups, in the event that your website has a major issue, we will communicate with the web hosting company on your behalf. There are no guarantees that your website will not be hacked, but we will do everything within our power to prevent it, as well as assist in the restoration and cleanup of the website with one of our backups.
        </Widowless>
      </StandardTextBlock>
    </li>
    <Spacer></Spacer>
    <li className="FAQItem" onClick={FAQToggle}>
      <h3 className="FAQQuestion">How does your monthly consulting calls work?</h3>
      <StandardTextBlock className="FAQAnswer" animateTextEnter={{ delay: 200 }}>
        <Widowless>
        Email is our preferred form of communication and provides the quickest response.  Consulting calls/video are reserved for our Premium Care clients. Clients use this time for online screen-share small updates or training.
        </Widowless>
      </StandardTextBlock>
    </li>
    <Spacer></Spacer>
    <li className="FAQItem" onClick={FAQToggle}>
      <h3 className="FAQQuestion">Does it matter what hosting provider I am using?</h3>
      <StandardTextBlock className="FAQAnswer" animateTextEnter={{ delay: 200 }}>
        <Widowless>
          We request that websites we manage are hosted on our servers but will work with website hosted on other hosting providers. Every website has different needs and clients have different budgets and hosting requirements.
        </Widowless>
      </StandardTextBlock>
    </li>
    <Spacer></Spacer>
    <li className="FAQItem" onClick={FAQToggle}>
      <h3 className="FAQQuestion">What if I need more support time/hours?</h3>
      <StandardTextBlock className="FAQAnswer" animateTextEnter={{ delay: 200 }}>
        <Widowless>
         If you have an enhancement or task that requires more than your dedicated monthly time, we will submit a quote for you to approve separately from your plan. We will evaluate requests that exceed your time on a case by case basis, and with approval, work past the time and bill for time exceeded at our current support rate of €50/hour.
        </Widowless>
      </StandardTextBlock>
    </li>
    <Spacer></Spacer>
    <li className="FAQItem" onClick={FAQToggle}>
      <h3 className="FAQQuestion">My website was not built by Axon Division, can I still be on a website care plan?</h3>
      <StandardTextBlock className="FAQAnswer" animateTextEnter={{ delay: 200 }}>
        <Widowless>
        Our plans are designed for sites that were built by us, we welcome clients who are looking for a long term partner with a professional and dedicated webmaster.

        With the evaluation, we will perform all the “Website Updates and Monitoring” tasks and make a full backup. Most importantly we will make a list of issues to fix and suggestions for improvement needed in order to proceed with the website on a monthly care plan. Based on your type of website, this Website Evaluation is a fixed 2-hour fee.
        </Widowless>
      </StandardTextBlock>
    </li>
    
  </ul>
}

const CarePlansProductsList = () => {

  const products = useAllProducts();

  return <ul className="CarePlansProductsList GridRow">
    {products.map(product=> 
      <li className={joinClassName("CarePlansProduct", product.isFeatured ? 'FeaturedProduct' : '')} key={product.name}>
        <a href={product.url} title={product.name + " Stripe gateway"}>
          <header>
            <h3 className="CarePlansProductName">{product.name}</h3>
            {/* {console.log(product)} */}
            <h3 className="CarePlansProductPrice"><strong>€{product.price}</strong> {product.pricingModel}</h3>
          </header>

          <ul className="CarePlansProduct__FeaturesList">

            {product.features.map(feature => 
              <li key={feature.name} className={feature.isActive ? 'featureActive' : ''}>{feature.name}</li>
              )}
          </ul>
          
          <CTAButton
            outlined
            // to={product.url}
            title={product.name}
            target="_blank"
          >Get Started</CTAButton>

        </a>
      </li>
    )}
    <li className="CarePlansProduct">
      <a title="Once-in-a-while Stripe gateway" href="https://checkout.stripe.com/c/pay/cs_test_a1HXpiNNajre9uBVPSiqrPTdrs1smnNn8qHffx150Bq9UMgqfIdviI29Ai#fidkdWxOYHwnPyd1blpxYHZxWjA0SWs2VkNJVFx2YDRjQjx%2FY21icURkM3Rxc21WYGpAckd8REc8MlJ8b0J9XEI2TGdcM0tLSzxLYF1ibUlTRzJKT05gZ2RSNmp3U2JSTU1sYVBHZ0dVNFJQNTVJUEc8M0lJTycpJ3VpbGtuQH11anZgYUxhJz8ncWB2cVo9ckhhV1dhVUQzfVU3cDE1NTUnKSd3YGNgd3dgd0p3bGJsayc%2FJ21xcXV2PyoqaWpmZGltanZxPz01NTUqJ3gl">
        <header>
          <h3 className="CarePlansProductName">Once-in-a-while service</h3>
          <h3 className="CarePlansProductPrice"><strong>€99</strong></h3>
        </header>
        <StandardTextBlock>
          <Widowless>
           If you would like us to tweak your website and bring all the plugins and themes up-to-date without any monthly commitment? then our Once-in-a-While payment might just be right for you! For €99.00 you will get all the above and up to 2 hours of Support Time.  
          </Widowless>
        </StandardTextBlock>
        <CTAButton
          outlined
          to="https://buy.stripe.com/4gw7ujdlcav7drybIM"
          title=""
          target="_blank"
        >Get Started</CTAButton>
      </a>
    </li>
  </ul>
}

export default PageCarePlans