import { useMicroCarePlanInfo } from "./micro-care-plan"
import { useEcommerceCarePlanInfo } from "./ecommerce-care-plan"
import { usePremiumCarePlanInfo } from "./premium-care-plan"
import { useEliteCarePlanInfo } from "./elite-care-plan"
import { useBespokeCarePlanInfo } from "./bespoke-care-plan"

export const useAllProducts = () => {
  const products = [
    useMicroCarePlanInfo(),
    useEcommerceCarePlanInfo(),
    usePremiumCarePlanInfo(),
    // useEliteCarePlanInfo(),
    useBespokeCarePlanInfo(),
  ]
  return products;
}