import { ProductFeature, ProductFeatureList, ProductMetaIngoWithProductFeatureWithProductFeatureList, ProductMetaInfo } from "../../types/app.types";

export const ProductMetaPremiumCarePlan : ProductMetaInfo = {
  name: "Premium Care Plan",
  price: 104.95,
  pricingModel: "monthly",
  url: "https://buy.stripe.com/28o6qf1CudHj1IQ4gn",
  isFeatured: true
}

export const usePremiumCarePlanInfo = () => {
  // const info: ProductMetaInfo = {
  //   ...ProductMetaMicroCarePlan,
  // }
  const info : ProductMetaIngoWithProductFeatureWithProductFeatureList = {
    ...ProductMetaPremiumCarePlan,
    features: [
      Feature1,
      Feature2,
      Feature3,
      Feature4,
      Feature5,
      Feature7,
      Feature6,
      Feature8,
      Feature9,
      GA4Feature,
    ]
  }
  return info;
}

export const Feature1 : ProductFeature = {
  name: 'WordPress core and plugin updates',
  isActive: true,
}


export const Feature2 : ProductFeature = {
  name: 'Website Backup',
  isActive: true,
}

export const Feature3 : ProductFeature = {
  name: 'Security Scanning',
  isActive: true,
}

export const Feature4 : ProductFeature = {
  name: 'Monthly Maintenance Report',
  isActive: true,
}

export const Feature5 : ProductFeature = {
  name: '24/7 uptime monitoring',
  isActive: true,
}

export const Feature6 : ProductFeature = {
  name: 'Support via ticket',
  isActive: true,
}

export const Feature7 : ProductFeature = {
  name: 'Woocommerce updates and support',
  isActive: true,
}

export const Feature8 : ProductFeature = {
  name: 'Image optimisation',
}

export const Feature9 : ProductFeature = {
  name: 'SEO monitoring and report',
}

export const GA4Feature : ProductFeature = {
  name: ' Analytics GA4 onboarding and setup',
}

// export const Feature10 : ProductFeature = {
//   name: 'Image optimisation',
// }